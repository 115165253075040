// https://redux-toolkit.js.org/usage/nextjs
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist';
import cartSlice from './slices/cart-slice';
import customerSlice from './slices/customer-slice';
import storage from './storage';
import { siteSettingsSlice } from '@/store/slices/site-settings-slice';

const rootReducer = combineReducers({
  cart: cartSlice.reducer,
  customer: customerSlice.reducer,
  siteSettings: siteSettingsSlice.reducer
});

const persistConfig = {
  key: 'root', // key for the localStorage entry
  timeout: 1000,
  storage, // storage provider (e.g., localStorage)
  whitelist: ['cart', 'customer', 'siteSettings']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const makeStore = () => {
  const store = configureStore({
    reducer: persistedReducer,

    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      });
    }
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['store']['getState']>;
export type AppDispatch = AppStore['store']['dispatch'];
