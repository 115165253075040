'use client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { QueryClientProviderProps } from '@tanstack/react-query/src/QueryClientProvider';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // With SSR, we usually want to set some default staleTime
      // above 0 to avoid refetching immediately on the client
      staleTime: process.env.NODE_ENV === 'development' ? 0 : 60 * 1000,
      retry: 0
    }
  }
});

export default function TanstackQueryProvider({ children }: Partial<QueryClientProviderProps>) {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
