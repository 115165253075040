import React from 'react';

const Price = ({
  price,
  className
}: {
  price: string;
  className?: string;
} & React.ComponentProps<'p'>) => (
  <p suppressHydrationWarning={true} className={className}>
    {price}
  </p>
);

export default Price;
