'use client';
import React from 'react';
import { HierarchicalMenuItem, Menu } from '@/lib/woocommerce/types';
import Link from 'next/link';
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from '@nextui-org/dropdown';
import { flatListToHierarchical } from '@/lib/utils';

const DesktopMenu = ({ menu }: { menu: Menu }) => {
  if (!menu || !menu.menuItems) return;

  const hierarchicalMenuItems = flatListToHierarchical(menu.menuItems.nodes);

  return hierarchicalMenuItems.length ? (
    <ul className="hidden w-full justify-center gap-6 py-1 text-sm md:flex md:items-center">
      {hierarchicalMenuItems.map((item: HierarchicalMenuItem) => (
        <li key={`${item.id}-desktop`} className="py-2">
          {item.children && item.children.length > 0 ? (
            <Dropdown
              showArrow
              offset={10}
              placement="bottom"
              classNames={{
                backdrop: ['top-[125px]']
              }}
              backdrop="opaque"
            >
              <DropdownTrigger className="btn-animated cursor-pointer">
                {item.label}
              </DropdownTrigger>
              <DropdownMenu aria-label={item.label}>
                {item.children.map((childItem) => {
                  return (
                    <DropdownItem
                      key={`${item.id}-${childItem.id}-desktop`}
                      textValue={childItem.label}
                    >
                      <Link className="block" href={childItem.path}>
                        {childItem.label}
                      </Link>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          ) : (
            <Link className="btn-animated" href={item.path}>
              {item.label}
            </Link>
          )}
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );
};

export default DesktopMenu;
