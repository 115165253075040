'use client';
import clsx from 'clsx';
import { Badge } from '@nextui-org/badge';
import { IoBasketOutline } from 'react-icons/io5';

export default function OpenCart({
  className,
  quantity
}: {
  className?: string;
  quantity?: number;
}) {
  return (
    <Badge
      content={quantity}
      size={quantity && quantity > 9 ? 'sm' : 'md'}
      isInvisible={!quantity || quantity < 1}
      className="bg-primary text-white"
    >
      <IoBasketOutline className={clsx('', className)} size="1.6em" />
    </Badge>
  );
}
