import { ReadonlyURLSearchParams } from 'next/navigation';
import { HierarchicalMenuItem, MenuItem } from '@/lib/woocommerce/types';

export const createUrl = (pathname: string, params: URLSearchParams | ReadonlyURLSearchParams) => {
  const paramsString = params.toString();
  const queryString = `${paramsString.length ? '?' : ''}${paramsString}`;

  return `${pathname}${queryString}`;
};

export const ensureStartsWith = (stringToCheck: string, startsWith: string) =>
  stringToCheck.startsWith(startsWith) ? stringToCheck : `${startsWith}${stringToCheck}`;

export const validateEnvironmentVariables = () => {
  const requiredEnvironmentVariables = ['SHOPIFY_STORE_DOMAIN', 'SHOPIFY_STOREFRONT_ACCESS_TOKEN'];
  const missingEnvironmentVariables = [] as string[];

  requiredEnvironmentVariables.forEach((envVar) => {
    if (!process.env[envVar]) {
      missingEnvironmentVariables.push(envVar);
    }
  });

  if (missingEnvironmentVariables.length) {
    throw new Error(
      `The following environment variables are missing. Your site will not work without them. Read more: https://vercel.com/docs/integrations/shopify#configure-environment-variables\n\n${missingEnvironmentVariables.join(
        '\n'
      )}\n`
    );
  }

  if (
    process.env.SHOPIFY_STORE_DOMAIN?.includes('[') ||
    process.env.SHOPIFY_STORE_DOMAIN?.includes(']')
  ) {
    throw new Error(
      'Your `SHOPIFY_STORE_DOMAIN` environment variable includes brackets (ie. `[` and / or `]`). Your site will not work with them there. Please remove them.'
    );
  }
};

export const formatDate = (date: Date | string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  };

  return new Date(date).toLocaleString(undefined, options);
};

export const flatListToHierarchical = (data: MenuItem[] = []): HierarchicalMenuItem[] => {
  const tree: HierarchicalMenuItem[] = [];
  const childrenOf: { [key: string]: HierarchicalMenuItem[] } = {};
  data.forEach((item) => {
    const newItem: HierarchicalMenuItem = { ...item };
    const id = newItem.id;
    const parentId = newItem.parentId;
    childrenOf[id] = childrenOf[id] || [];
    newItem.children = childrenOf[id];
    if (parentId) {
      childrenOf[parentId] = childrenOf[parentId] || [];
      childrenOf[parentId]?.push(newItem);
    } else {
      tree.push(newItem);
    }
  });
  return tree;
};

export function getURIFromURL(url: string): string {
  try {
    const myURL = new URL(url);
    let path: string = myURL.pathname;
    const query: string = myURL.search;

    if (path.endsWith('/')) {
      path = path.slice(0, -1);
    }

    const uri: string = `${path}${query ? '?' + query : ''}`;

    return uri;
  } catch (error) {
    return '';
  }
}

export function makePlural(word: string): string {
  if (
    word.endsWith('s') ||
    word.endsWith('x') ||
    word.endsWith('z') ||
    word.endsWith('ch') ||
    word.endsWith('sh')
  ) {
    return word + 'es';
  } else if (word.endsWith('y')) {
    const withoutY = word.slice(0, -1);
    return withoutY + 'ies';
  } else {
    return word + 's';
  }
}

export const formatCurrency = (
  amount: number | string,
  currencySymbol: string = '$',
  position: 'left' | 'right' = 'left'
): string => {
  let amountFixed: number;
  if (typeof amount == 'string') {
    amountFixed = parseFloat(amount.replace(/[^0-9.-]+/g, ''));
  } else {
    amountFixed = amount;
  }

  const formattedAmount = (Math.floor(amountFixed * 100) / 100).toFixed(2);
  return position === 'left'
    ? `${currencySymbol}${formattedAmount}`
    : `${formattedAmount}${currencySymbol}`;
};
