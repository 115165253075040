'use client';

import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import LoadingDots from '@/components/loading-dots';
import type { CartItem } from '@/lib/woocommerce/types';
import { updateCartProductQuantity } from '@/lib/woocommerce/woograph';
import { useAppDispatch } from '@/store/hooks';
import { setCart } from '@/store/slices/cart-slice';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MouseEventHandler } from 'react';

const SubmitButton = ({
  type,
  pending,
  onClick
}: {
  type: 'plus' | 'minus';
  pending: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>; // Define the expected type for onClick
}) => {
  return (
    <button
      aria-label={type === 'plus' ? 'Increase item quantity' : 'Reduce item quantity'}
      aria-disabled={pending}
      className={clsx(
        'ease flex h-full min-w-[36px] max-w-[36px] flex-none items-center justify-center rounded-full px-2 transition-all duration-200 hover:border-neutral-800 hover:opacity-80',
        {
          'cursor-not-allowed': pending,
          'ml-auto': type === 'minus'
        }
      )}
      disabled={pending}
      onClick={onClick}
    >
      {pending ? (
        <LoadingDots className="bg-black dark:bg-white" />
      ) : type === 'plus' ? (
        <PlusIcon className="h-4 w-4 dark:text-neutral-500" />
      ) : (
        <MinusIcon className="h-4 w-4 dark:text-neutral-500" />
      )}
    </button>
  );
};

export function EditItemQuantityButton({ item, type }: { item: CartItem; type: 'plus' | 'minus' }) {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { mutate: mutateUpdateQuantity, isPending: isUpdateQuantityPending } = useMutation({
    mutationFn: () =>
      updateCartProductQuantity([
        {
          key: item.key,
          quantity: type === 'plus' ? item.quantity + 1 : item.quantity - 1
        }
      ]),
    onSuccess: async (data) => {
      dispatch(setCart(data.cart));
      queryClient.invalidateQueries({ queryKey: ['cart-page'] });
    }
  });

  const handleOnClick = () => {
    mutateUpdateQuantity();
  };

  return (
    <>
      <SubmitButton type={type} onClick={handleOnClick} pending={isUpdateQuantityPending} />
      <p aria-live="polite" className="sr-only" role="status">
        CartItem updated.
      </p>
    </>
  );
}
