"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";

export const GA4Provider = () => {
  const pathname = usePathname();

  useEffect(() => {
    if(window && window.dataLayer) {
        window.dataLayer.push({
        event: "page_view",
        page: window.location.href,
        });
    }
  }, [pathname]);

  return null;
};
