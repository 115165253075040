'use client';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { AuthModalType } from '@/components/layout/navbar/account-panel/sign-in/index';
import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/modal';
import { Input } from '@nextui-org/input';
import { Button } from '@nextui-org/button';
import LoadingDots from '@/components/loading-dots';
import { useMutation } from '@tanstack/react-query';
import { sendPasswordResetEmail } from '@/lib/woocommerce/woograph';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ForgotPasswordModal = ({
  isOpen,
  closeModal,
  setActiveModal
}: {
  isOpen: boolean;
  closeModal: () => void;
  setActiveModal: Dispatch<SetStateAction<AuthModalType>>;
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState('');

  const handleCloseModal = () => {
    resetForgotPasswordMutate();
    closeModal();
    setActiveModal('login');
  };

  const handleSendButtonClick = async () => {
    const forgotPasswordArgs: Record<string, any> = {
      username: email
    };

    if (process.env.NODE_ENV === 'production') {
      if (!executeRecaptcha) {
        console.error('ReCAPTCHA is not available.');
        return;
      }

      const gRecaptchaToken = await executeRecaptcha('forgotPasswordSubmit');

      forgotPasswordArgs.gRecaptchaToken = gRecaptchaToken;
    }

    mutateForgotPassword(forgotPasswordArgs);
  };

  const handleEnterKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter' && !isForgotPasswordPending) {
      handleSendButtonClick();
    }
  };

  const {
    mutate: mutateForgotPassword,
    error: errorForgotPassword,
    reset: resetForgotPasswordMutate,
    isPending: isForgotPasswordPending,
    data: dataForgotPassword
  } = useMutation({
    mutationKey: ['forgot-password'],
    mutationFn: sendPasswordResetEmail,
    onSuccess: (data) => {
      console.log(data);
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      className="relative z-50 py-4"
      classNames={{ header: ['flex-col font-normal text-center'], body: ['gap-y-5'] }}
      hideCloseButton
      placement="center"
      radius="sm"
    >
      <ModalContent>
        <ModalHeader>
          {!dataForgotPassword ? (
            <>
              <div className="text-2xl">Forgot Your Password?</div>
              <div className="text-medium text-foreground-400">
                Enter your email to receive a password reset link.
              </div>
            </>
          ) : (
            <>
              <div className="text-2xl">Recovery Link Sent!</div>
            </>
          )}
        </ModalHeader>

        <ModalBody>
          {errorForgotPassword && (
            <div className="rounded border border-red-400 bg-red-300 px-4 py-3 text-sm text-red-800">
              Please enter the email address associated with your account.
            </div>
          )}
          {!dataForgotPassword ? (
            <div className="flex flex-col gap-y-4">
              <div>
                <Input
                  id="input-email"
                  onKeyDown={handleEnterKeyPress}
                  value={email}
                  onValueChange={setEmail}
                  variant="bordered"
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  size="md"
                  radius="sm"
                  isDisabled={isForgotPasswordPending}
                />
              </div>
              <div>
                <Button
                  onClick={handleSendButtonClick}
                  fullWidth
                  disabled={isForgotPasswordPending}
                  spinner={<LoadingDots className="bg-white" />}
                  isIconOnly={isForgotPasswordPending}
                  isLoading={isForgotPasswordPending}
                  aria-label="Forgot Pasword"
                  aria-disabled={isForgotPasswordPending}
                  className="btn-primary w-full"
                  size="lg"
                  disableAnimation
                >
                  Send Reset Link
                </Button>
              </div>
              <div className="text-center text-sm">
                You can return by clicking the &nbsp;
                <button
                  type="button"
                  onClick={() => setActiveModal('register')}
                  className="text-indigo-500 underline"
                >
                  Sign In
                </button>
                &nbsp;or&nbsp;
                <button
                  type="button"
                  onClick={() => setActiveModal('register')}
                  className="text-indigo-500 underline"
                >
                  Sign Up
                </button>
                &nbsp;page.
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-y-6">
              <div className="text-center text-medium text-foreground-600">
                If the email address you entered exists in our database, we will send you an email
                with a password reset link.
              </div>
              <Button size="sm" color="primary" onClick={handleCloseModal}>
                Close
              </Button>
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ForgotPasswordModal;
