'use client';

import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { createUrl } from '@/lib/utils';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import { Input } from '@nextui-org/input';
import { Spinner } from '@nextui-org/spinner';
import React, { useEffect, useState } from 'react';

export default function Search() {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);
    handleComplete();

    return () => {
      handleStart();
    };
  }, [pathname, searchParams]);

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);

    const val = e.target as HTMLFormElement;
    const search = val.search as HTMLInputElement;
    const newParams = new URLSearchParams(searchParams.toString());

    if (search.value) {
      newParams.set('q', search.value);
    } else {
      newParams.delete('q');
    }

    router.push(createUrl('/search', newParams));
  }

  return (
    <form onSubmit={onSubmit} className="relative w-full">
      <Input
        key={searchParams?.get('q')}
        type="text"
        name="search"
        radius="full"
        size="sm"
        placeholder="Search for products"
        autoComplete="off"
        variant="bordered"
        defaultValue={searchParams?.get('q') || ''}
        className="dark:bg-transparent dark:text-white dark:placeholder:text-neutral-400"
        classNames={{
          mainWrapper: ['group'],
          inputWrapper: [
            'py-0 pl-5 pr-0 border border-2 border-black overflow-hidden',
            'hover:border-black'
          ],
          innerWrapper: ['pb-0'],
          input: ['text-md']
        }}
        endContent={
          <div className="flex h-full transform items-center pr-1">
            <div className="absolute inset-0 -z-10 scale-0 rounded-r-full bg-primary transition-all duration-1000 group-focus-within:scale-100 group-hover:scale-100 "></div>
            <div className={`rounded-full bg-primary p-2 text-white`}>
              {loading ? (
                <Spinner className="text-white" size="sm" color="white" />
              ) : (
                <MagnifyingGlassIcon className="h-6" />
              )}
            </div>
          </div>
        }
      />
    </form>
  );
}
