'use client';
import React from 'react';
import { NextUIProviderProps } from '@nextui-org/react';
import { useRouter } from 'next/navigation';
import { NextUIProvider } from '@nextui-org/system';

export const CustomNextUIProvider: React.FC<NextUIProviderProps> = ({
  children,
  className,
  ...props
}) => {
  const router = useRouter();

  return (
    <NextUIProvider navigate={router.push} className={className} {...props}>
      {children}
    </NextUIProvider>
  );
};

export default CustomNextUIProvider;
