import React from 'react';

const SignInButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button className="btn-animated" onClick={onClick}>
      Sign In
    </button>
  );
};

export default SignInButton;
