'use client';
import React, { useState } from 'react';
import CountrySelectorModal from '@/components/layout/country-selector';
import { Avatar } from '@nextui-org/avatar';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Tooltip } from '@nextui-org/tooltip';
import { Button } from '@nextui-org/button';
import { setCountrySelectorTooltip } from '@/store/slices/site-settings-slice';

const CountrySelector = () => {
  const dispatch = useAppDispatch();
  const { countrySelectorTooltip: isTooltipOpen } = useAppSelector((state) => state.siteSettings);

  const selectedShipmentCountry = useAppSelector(
    (state) => state.customer?.selectedShipmentCountry
  );

  const [isCountrySelectorModalOpen, setIsCountrySelectorModalOpen] = useState(false);

  const handleCountrySelectModalOpen = (isOpen: boolean) => {
    dispatch(setCountrySelectorTooltip(false));
    setIsCountrySelectorModalOpen(isOpen);
  };

  return (
    <>
      {selectedShipmentCountry ? (
        <Tooltip
          content={
            <>
              <div>
                We&apos;re showing you items that ship to {selectedShipmentCountry.name}. To see
                items that ship to a different country, change your delivery country.
              </div>
              <div className="flex w-full justify-end gap-x-2">
                <Button onClick={() => dispatch(setCountrySelectorTooltip(false))} size="sm">
                  Dismiss
                </Button>
                <Button
                  onClick={() => handleCountrySelectModalOpen(true)}
                  size="sm"
                  color="warning"
                >
                  Change Country
                </Button>
              </div>
            </>
          }
          isOpen={isTooltipOpen?.isOpen}
          radius="none"
          showArrow
          offset={15}
          classNames={{ base: 'max-w-sm', content: 'p-4' }}
        >
          <div
            className="flex cursor-pointer items-center gap-x-2 text-sm"
            onClick={() => handleCountrySelectModalOpen(true)}
          >
            <div>
              <Avatar
                isBordered
                alt={selectedShipmentCountry.name}
                className="h-7 w-7"
                src={`https://flagcdn.com/${selectedShipmentCountry.code.toLowerCase()}.svg`}
              />
            </div>
            <div className="flex flex-col gap-y-0 text-xs">
              <span className="text-xs">Deliver to:</span> {selectedShipmentCountry.name}
            </div>
          </div>
        </Tooltip>
      ) : (
        <div onClick={() => handleCountrySelectModalOpen(true)}>Select Country</div>
      )}
      <CountrySelectorModal
        isModalOpen={isCountrySelectorModalOpen}
        onOpenChange={handleCountrySelectModalOpen}
      />
    </>
  );
};

export default CountrySelector;
