import { createSlice } from '@reduxjs/toolkit';
import { Cart } from '@/lib/woocommerce/types';

const initialCartState: Cart | null = null;

export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialCartState,
  reducers: {
    setCart(state, action) {
      return action.payload;
    }
  }
});

export const { setCart } = cartSlice.actions;
export default cartSlice;
