'use client';
import { getCustomer } from '@/lib/woocommerce/woograph';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { CustomerState, setCustomerState } from '@/store/slices/customer-slice';
import { setSignInModalIsOpen } from '@/store/slices/site-settings-slice';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import AccountButton from './account-button';
import RegisterModal from './register-modal';
import SignInButton from './sign-in-button';
import SignInModal from './sign-in-modal';
import ForgotPasswordModal from '@/components/layout/navbar/account-panel/sign-in/forgot-password-modal';

export type AuthModalType = 'login' | 'register' | 'forgot-password';

const SignIn = () => {
  const dispatch = useAppDispatch();

  const { signInModal } = useAppSelector((state) => state.siteSettings);
  const openModal = () => dispatch(setSignInModalIsOpen(true));
  const closeModal = () => dispatch(setSignInModalIsOpen(false));
  const [activeModal, setActiveModal] = useState<AuthModalType>('login');
  const { data: customer } = useQuery({
    queryKey: ['customer'],
    queryFn: async () => {
      const customer = await getCustomer();
      const customerState: CustomerState = { customer };
      if (customer.id === 'guest') {
        customerState.user = undefined;
      }

      dispatch(setCustomerState(customerState));
      return customer;
    }
  });

  const customerState = useAppSelector((state) => state.customer);

  return (
    <div>
      {customerState?.user ? <AccountButton /> : <SignInButton onClick={openModal} />}
      <GoogleReCaptchaProvider
        scriptProps={{ defer: true }}
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
      >
        {activeModal == 'login' && (
          <SignInModal
            isOpen={signInModal.isOpen}
            closeModal={closeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal == 'register' && (
          <RegisterModal
            isOpen={signInModal.isOpen}
            closeModal={closeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal == 'forgot-password' && (
          <ForgotPasswordModal
            isOpen={signInModal.isOpen}
            closeModal={closeModal}
            setActiveModal={setActiveModal}
          />
        )}
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default SignIn;
