import { createSlice } from '@reduxjs/toolkit';
import customerStateSlice from '@/store/slices/customer-slice';

export const siteSettingsSlice = createSlice({
  name: 'siteSettings',
  initialState: {
    signInModal: {
      isOpen: false
    },
    countrySelectorTooltip: {
      isOpen: true
    }
  },
  reducers: {
    setSignInModalIsOpen: (state, action) => {
      return { ...state, signInModal: { isOpen: action.payload } };
    },
    setCountrySelectorTooltip: (state, action) => {
      return { ...state, countrySelectorTooltip: { isOpen: action.payload } };
    }
  }
});

export const { setSignInModalIsOpen, setCountrySelectorTooltip } = siteSettingsSlice.actions;
export default customerStateSlice;
