'use client';
import { signIn } from '@/lib/woocommerce/woograph';
import { useAppDispatch } from '@/store/hooks';
import { setCustomerState } from '@/store/slices/customer-slice';
import { Button } from '@nextui-org/button';
import { Checkbox } from '@nextui-org/checkbox';
import { Input } from '@nextui-org/input';
import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/modal';
import { EyeFilledIcon, EyeSlashFilledIcon } from '@nextui-org/shared-icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Link from 'next/link';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import LoadingDots from '@/components/loading-dots';
import { AuthModalType } from '@/components/layout/navbar/account-panel/sign-in/index';

const SignInModal = ({
  isOpen,
  closeModal,
  setActiveModal
}: {
  isOpen: boolean;
  closeModal: () => void;
  setActiveModal: Dispatch<SetStateAction<AuthModalType>>;
}) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [staySignedChecked, setStaySignedChecked] = useState(false);

  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const togglePasswordVisibility = () => setIsPasswordVisible(!isPasswordVisible);

  const handleCloseModal = () => {
    resetSignInMutate();
    closeModal();
  };

  const {
    mutate: mutateLogin,
    error: errorLogin,
    reset: resetSignInMutate,
    isPending: isSignInPending,
    data: loginData
  } = useMutation({
    mutationKey: ['login'],
    mutationFn: signIn,
    onSuccess: (data, variables, context) => {
      setEmail('');
      setPassword('');
      localStorage.setItem('authToken', data.authToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('woocommerce-session', data.user.wooSessionToken);
      dispatch(setCustomerState({ customer: data.customer, user: data.user }));
      queryClient.resetQueries({ queryKey: ['cart'] });
      queryClient.resetQueries({ queryKey: ['cart-page'] });

      closeModal();
    }
  });

  const handleLoginButtonClick = async () => {
    const signInArgs: Record<string, any> = {
      username: email,
      password
    };

    if (process.env.NODE_ENV === 'production') {
      if (!executeRecaptcha) {
        console.error('ReCAPTCHA is not available.');
        return;
      }

      const gRecaptchaToken = await executeRecaptcha('loginSubmit');

      signInArgs.gRecaptchaToken = gRecaptchaToken;
    }

    mutateLogin(signInArgs);
  };

  const handleEnterKeyPress = async (e: { key: string }) => {
    if (e.key === 'Enter' && !isSignInPending) {
      handleLoginButtonClick();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      className="relative z-50 py-4"
      classNames={{ header: ['flex-col font-normal text-center'], body: ['gap-y-5'] }}
      hideCloseButton
      placement="center"
      radius="sm"
    >
      <ModalContent>
        <ModalHeader>
          <div className="text-2xl">Sign In</div>
          <div className="text-medium text-foreground-400">Log in to your account to continue</div>
        </ModalHeader>

        <ModalBody>
          {errorLogin && (
            <div className="rounded border border-red-400 bg-red-300 px-4 py-3 text-sm text-red-800">
              {errorLogin.message}
            </div>
          )}

          <div className="flex flex-col gap-y-2">
            <div>
              <Input
                id="input-email"
                onKeyDown={handleEnterKeyPress}
                value={email}
                onValueChange={setEmail}
                variant="bordered"
                type="email"
                label="Email"
                placeholder="Enter your email"
                size="md"
                radius="sm"
              />
            </div>

            <div>
              <Input
                id="input-password"
                label="Password"
                variant="bordered"
                placeholder="Enter your password"
                value={password}
                onKeyDown={handleEnterKeyPress}
                onValueChange={setPassword}
                size="md"
                radius="sm"
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordVisible ? (
                      <EyeSlashFilledIcon className="pointer-events-none text-2xl text-default-400" />
                    ) : (
                      <EyeFilledIcon className="pointer-events-none text-2xl text-default-400" />
                    )}
                  </button>
                }
                type={isPasswordVisible ? 'text' : 'password'}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <Checkbox
              isSelected={staySignedChecked}
              onValueChange={setStaySignedChecked}
              size="md"
              className="text-sm"
            >
              Stay signed in
            </Checkbox>

            <button onClick={() => setActiveModal('forgot-password')} className="text-sm underline">
              Forgot your password?
            </button>
          </div>
          <div>
            <Button
              onClick={handleLoginButtonClick}
              fullWidth
              disabled={isSignInPending}
              spinner={<LoadingDots className="bg-white" />}
              isIconOnly={isSignInPending}
              isLoading={isSignInPending}
              aria-label="Sign In"
              aria-disabled={isSignInPending}
              className="btn-primary w-full"
              size="lg"
              disableAnimation
            >
              Sign In
            </Button>
          </div>
          {/*
          <div className="flex items-center gap-4">
            <hr className="h-divider w-full flex-1 border-none bg-divider" role="separator" />
            <p className="shrink-0 text-tiny text-default-500">OR</p>
            <hr className="h-divider w-full flex-1 border-none bg-divider" role="separator" />
          </div>
          <div>
            <Button
              fullWidth
              variant="bordered"
              radius="sm"
              aria-label="Sign in with Google"
              disabled={isSignInPending}
              size="lg"
              className="text-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                focusable="false"
                tabIndex={-1}
                className="iconify iconify--flat-color-icons"
                width="24"
                height="24"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#FFC107"
                  d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8c-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C12.955 4 4 12.955 4 24s8.955 20 20 20s20-8.955 20-20c0-1.341-.138-2.65-.389-3.917"
                ></path>
                <path
                  fill="#FF3D00"
                  d="m6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C16.318 4 9.656 8.337 6.306 14.691"
                ></path>
                <path
                  fill="#4CAF50"
                  d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44"
                ></path>
                <path
                  fill="#1976D2"
                  d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002l6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917"
                ></path>
              </svg>
              Continue with Google
            </Button>
          </div>
          */}
          <div className="text-center text-sm">
            Need to create an account?&nbsp;
            <button
              type="button"
              onClick={() => setActiveModal('register')}
              className="text-indigo-500 underline"
            >
              Sign Up
            </button>
          </div>
          <div className="flex flex-col gap-y-1">
            <div className="text-center text-xs">
              By clicking &#34;Sign In&#34; or &#34;Register&#34; you agree to&#160;
              <Link
                href="/legal/terms-of-use"
                className="text-indigo-500 underline"
                target="_blank"
              >
                Terms of Use
              </Link>
              &nbsp;and&nbsp;
              <Link
                href="/legal/privacy-policy"
                className="text-indigo-500 underline"
                target="_blank"
              >
                Privacy Policy
              </Link>
              .
            </div>
            <div className="text-center text-xs">
              We may send you communications; you may change your preferences in your account
              settings. We&#39;ll never post without your permission.
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SignInModal;
