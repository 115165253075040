import(/* webpackMode: "eager" */ "/app/app/(frontend)/globals.css");
import(/* webpackMode: "eager" */ "/app/components/ga4-provider.tsx");
import(/* webpackMode: "eager" */ "/app/components/layout/footer/footer-menu-item.tsx");
import(/* webpackMode: "eager" */ "/app/components/layout/navbar/account-panel/account-panel.tsx");
import(/* webpackMode: "eager" */ "/app/components/layout/navbar/desktop-menu.tsx");
import(/* webpackMode: "eager" */ "/app/components/layout/navbar/mobile-menu.tsx");
import(/* webpackMode: "eager" */ "/app/components/layout/navbar/search.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.1.3_react@18.2.0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.1.3_react@18.2.0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.1.3_react@18.2.0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.3_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.3_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.3_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"app/(frontend)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-geist-sans\",\"src\":[{\"path\":\"../../fonts/GeistSans-Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../fonts/GeistSans-Italic.woff\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../fonts/GeistSans-Medium.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../fonts/GeistSans-MediumItalic.woff\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../fonts/GeistSans-Bold.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../fonts/GeistSans-BoldItalic.woff\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../../fonts/GeistSans-Black.woff\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../../fonts/GeistSans-BlackItalic.woff\",\"weight\":\"900\",\"style\":\"italic\"}]}],\"variableName\":\"GeistSans\"}")