import React from 'react';
import {
  ArrowLeftOnRectangleIcon,
  ChatBubbleLeftEllipsisIcon,
  Cog6ToothIcon,
  TagIcon
} from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setCustomerState } from '@/store/slices/customer-slice';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger
} from '@nextui-org/dropdown';
import { User } from '@nextui-org/user';
import { Divider } from '@nextui-org/divider';
import { BuildingStorefrontIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { HiOutlineUserCircle } from 'react-icons/hi2';
import { IoCaretDownSharp } from 'react-icons/io5';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logout } from '@/lib/woocommerce/woograph';

const AccountButton = () => {
  const queryClient = useQueryClient();

  const dispatch = useAppDispatch();
  const customerState = useAppSelector((state) => state.customer);

  const { mutate: mutateLogout, isPending: isLogoutPending } = useMutation({
    mutationFn: logout,
    onSuccess: async (data) => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('woocommerce-session');
      dispatch(
        setCustomerState({
          user: undefined
        })
      );
      queryClient.invalidateQueries({ queryKey: ['cart-page'] });
      queryClient.invalidateQueries({ queryKey: ['customer'] });
      queryClient.invalidateQueries({ queryKey: ['cart'] });
    }
  });

  if (!customerState) return;

  const onSignOutBtnClick = () => {
    mutateLogout();
  };

  return (
    <Dropdown
      placement="bottom"
      showArrow
      offset={10}
      backdrop="opaque"
      classNames={{
        backdrop: ['top-[125px]']
      }}
    >
      <DropdownTrigger>
        <div className="btn-animated-circle flex cursor-pointer items-center gap-x-1.5">
          <HiOutlineUserCircle size="1.6em" /> <IoCaretDownSharp size="0.8em" />
        </div>
      </DropdownTrigger>

      <DropdownMenu
        topContent={
          <div className="mb-1 mt-2">
            <User
              name={customerState.customer.displayName}
              avatarProps={{
                src: customerState.user?.avatar.url
              }}
            />
            <Divider className="mt-1" />
          </div>
        }
        aria-label="Account Menu"
      >
        <DropdownSection showDivider>
          <DropdownItem
            startContent={<BuildingStorefrontIcon className="w-5" />}
            aria-label="My Orders"
          >
            <Link href="/account/my-orders" className="block">
              My orders
            </Link>
          </DropdownItem>
          <DropdownItem
            startContent={<ChatBubbleLeftEllipsisIcon className="w-5" />}
            aria-label="Tickets"
          >
            <Link href="/account/tickets" className="block">
              Tickets
            </Link>
          </DropdownItem>
        </DropdownSection>
        <DropdownSection showDivider>
          <DropdownItem
            href="/security"
            startContent={<TagIcon className="w-5" />}
            aria-label="Special Offers"
          >
            Special Offers
          </DropdownItem>
        </DropdownSection>
        <DropdownSection>
          <DropdownItem
            href="/security"
            startContent={<Cog6ToothIcon className="w-5" />}
            aria-label="Account Settings"
          >
            Account Settings
          </DropdownItem>
          <DropdownItem
            className="cursor-pointer"
            startContent={<ArrowLeftOnRectangleIcon className="w-5" />}
            onClick={onSignOutBtnClick}
            aria-labelledby="Sign Out"
          >
            Sign Out
          </DropdownItem>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AccountButton;
