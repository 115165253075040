import { Key, useState } from 'react';

import { Button } from '@nextui-org/button';
import {
  Modal,
  useDisclosure,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@nextui-org/modal';
import { Avatar } from '@nextui-org/avatar';
import { Autocomplete, AutocompleteItem } from '@nextui-org/autocomplete';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setCustomerState } from '@/store/slices/customer-slice';
import { getCountries, setCustomerAddress } from '@/lib/woocommerce/woograph';
import { Country } from '@/lib/woocommerce/types';

export default function CountrySelectorModal({
  isModalOpen,
  onOpenChange
}: {
  isModalOpen: boolean;
  onOpenChange: (_isOpen: boolean) => void;
}) {
  const queryClient = useQueryClient();
  const { onClose } = useDisclosure();

  const [countryInputValue, setCountryInputValue] = useState<string | undefined>(undefined);

  const dispatch = useAppDispatch();
  const selectedShipmentCountry = useAppSelector(
    (state) => state.customer?.selectedShipmentCountry
  );

  const { data: countries } = useSuspenseQuery<Country[]>({
    queryKey: ['countries'],
    queryFn: async () => {
      const countries = await getCountries();
      return countries;
    }
  });

  const updateShippingCountry = async () => {
    if (!countryInputValue) return;

    mutateSetLocale({ country: countryInputValue.toUpperCase() });
  };

  const handleOnOpenChange = (isOpen: boolean) => {
    onOpenChange(isOpen);
  };

  const handleCountryInputChange = (key: Key) => {
    setCountryInputValue(key as string);
  };

  const {
    mutate: mutateSetLocale,
    error: errorSetLocale,
    isPending: isSetLocalePending,
    data: dataSetLocale
  } = useMutation({
    mutationFn: setCustomerAddress,
    onSuccess: async (data, variables, context) => {
      dispatch(
        setCustomerState({
          ...data
        })
      );

      await queryClient.resetQueries({ queryKey: ['cart-page'] });

      handleOnOpenChange(false);
    }
  });

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={onClose}
        onOpenChange={handleOnOpenChange}
        size="md"
        radius="sm"
        placement="center"
        classNames={{ closeButton: ['top-4 right-4'] }}
      >
        <ModalContent>
          <ModalHeader className="flex-col pb-3 font-normal">
            <div className="text-xl">Select Shipment Country</div>
            <span className="text-sm text-foreground-400">
              Select a shipment country to shipping and package details, offers and better
              experience.
            </span>
          </ModalHeader>
          <ModalBody>
            <Autocomplete
              label="Choose Shipment Country"
              selectedKey={
                countryInputValue
                  ? countryInputValue
                  : selectedShipmentCountry
                  ? selectedShipmentCountry.code
                  : undefined
              }
              radius="sm"
              onSelectionChange={handleCountryInputChange}
            >
              {countries.map((country) => {
                return (
                  <AutocompleteItem
                    key={country.code}
                    value={country.code}
                    startContent={
                      <Avatar
                        alt={country.name}
                        className="h-6 w-6"
                        src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
                      />
                    }
                  >
                    {country.name}
                  </AutocompleteItem>
                );
              })}
            </Autocomplete>
          </ModalBody>
          <ModalFooter>
            <Button
              radius="sm"
              color="primary"
              fullWidth
              onPress={updateShippingCountry}
              isLoading={isSetLocalePending}
            >
              Update Shipping Country
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
