import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Country, Customer, User } from '@/lib/woocommerce/types';
import { RootState } from '@/store';
import { getCountries, setCustomerAddress } from '@/lib/woocommerce/woograph';

export type CustomerState = {
  customer: Customer;
  user?: User;
  selectedShipmentCountry?: Country;
} | null;

export const setCustomerStateAsync = createAsyncThunk(
  'customer/setCustomerStateAsync',
  async (payload: Partial<CustomerState>, ThunkAPI) => {
    const { customer } = (await ThunkAPI.getState()) as RootState;

    let countryCode: string | undefined;

    if (!customer?.selectedShipmentCountry) {
      try {
        if (customer?.customer.billing.country) {
          countryCode = customer?.customer.billing.country;
        } else {
          const fetchUserCountry = await fetch('https://api.country.is/');
          const userCountryData = await fetchUserCountry.json();
          countryCode = userCountryData.country;
        }
      } catch {
        console.error("Customer country didn't fetch!");
      }
    } else if (customer.selectedShipmentCountry.code !== payload!.customer?.billing.country) {
      if (!payload!.customer?.billing.country) {
        countryCode = customer.selectedShipmentCountry.code;
        const customerAddress = await setCustomerAddress({ country: countryCode });
        payload!.customer = customerAddress.customer;
      } else {
        countryCode = payload!.customer.billing.country;
      }
    }

    if (countryCode) {
      const countries: Country[] = await getCountries();
      payload!.selectedShipmentCountry = countries.find(({ code }) => code === countryCode);
    }

    return payload;
  }
);

export const customerStateSlice = createSlice({
  name: 'customer',
  initialState: null as CustomerState,
  reducers: {
    setCustomerStateA(state, action) {
      return { ...state, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setCustomerStateAsync.fulfilled, (state, action) => {
      return { ...state, ...action.payload } as CustomerState | null;
    });
  }
});

export const setCustomerState = setCustomerStateAsync;
export default customerStateSlice;
