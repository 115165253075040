'use client';
import CartModal from './modal';
import { useQuery } from '@tanstack/react-query';
import { getCart } from '@/lib/woocommerce/woograph';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setCart } from '@/store/slices/cart-slice';

export default function Cart() {
  const dispatch = useAppDispatch();
  const { data: cart } = useQuery({
    queryKey: ['cart'],
    queryFn: async () => {
      const data = await getCart();
      dispatch(setCart(data));
      return data;
    }
  });

  const cartState = useAppSelector((state) => state.cart);

  return (
    <div className="relative flex items-center justify-center">
      <CartModal cart={cartState ?? cart} />
    </div>
  );
}
