'use client';
import React, { Suspense } from 'react';
import CountrySelector from '@/components/layout/navbar/country-selector';
import SignIn from 'components/layout/navbar/account-panel/sign-in';
import OpenCart from '@/components/cart/open-cart';
import Cart from '@/components/cart';
import NextUIProvider from '@/components/layout/providers/next-u-i-provider';
import ReduxProvider from '@/components/layout/providers/redux-provider';
import TanstackQueryProvider, {
  queryClient
} from '@/components/layout/providers/tanstack-query-provider';

const AccountPanel = () => {
  return (
    <NextUIProvider className="flex flex-1 items-center justify-between">
      <TanstackQueryProvider client={queryClient}>
        <ReduxProvider>
          <div className="btn-animated relative flex flex-col items-start justify-center gap-0 text-black">
            <CountrySelector />
          </div>
          <div className="relative flex items-center justify-center text-black">
            <Suspense>
              <SignIn />
            </Suspense>
          </div>
          <Suspense fallback={<OpenCart />}>
            <Cart />
          </Suspense>
        </ReduxProvider>
      </TanstackQueryProvider>
    </NextUIProvider>
  );
};

export default AccountPanel;
