'use client';
import React, { useRef } from 'react';
import { Provider } from 'react-redux';
import { makeStore, AppStore } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactNode } from 'react';

export const store = makeStore();

export default function ReduxProvider({
  children,
  loading = null
}: {
  children: ReactNode;
  loading?: ReactNode;
}) {
  const storeRef = useRef<AppStore>();
  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = store;
  }

  return (
    <Provider store={storeRef.current?.store}>
      <PersistGate loading={loading} persistor={storeRef.current?.persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}
